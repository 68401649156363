import React from "react"
import cuid from "cuid"
import { Grid, Typography, Container, Box, Link } from "@mui/material"

import { StaticImage } from "gatsby-plugin-image"

import { Center, FancyHr } from "../Layout"
import { AnimateOnScroll } from "../AnimateOnScroll"

import AboutHero from "./AboutHero"
import { Segment } from "../Segment"
import { Section } from "../Section"

const foundationOfficers = [
  {
    id: cuid(),
    title: "President",
    name: "Clarence D. Williams",
  },
  {
    id: cuid(),
    title: "Vice President",
    name: "Walter Bond",
  },
  {
    id: cuid(),
    title: "2nd Vice President",
    name: "Joseph Gibbons",
  },
  {
    id: cuid(),
    title: "Secretary",
    name: "Albert Green",
  },
  {
    id: cuid(),
    title: "Treasurer",
    name: "Robert C. Osborne",
  },
]

const About = () => {
  return (
    <>
      <AboutHero />
      <Segment>
        <AnimateOnScroll animateIn="fadeIn" animateOut="fadeIn">
          <Container>
            <Section divider>
              <Grid container mb={4}>
                <Grid item xs={12}>
                  <Center>
                    <StaticImage
                      src="../../images/logo-2018.svg"
                      alt="elb logo"
                      placeholder="blurred"
                      width={550}
                    />
                  </Center>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h4" color="primary" align="center">
                    “Our Leaders of Today Produce our Leaders of Tomorrow" -
                    Michael “Dr.J” Joyner
                  </Typography>
                  <FancyHr />
                  <Typography align="center">
                    The membership of the ELB Foundation shall be limited to
                    individuals who are active members of the Epsilon Lambda
                    Boule of Sigma Pi Phi Fraternity.
                  </Typography>
                </Grid>
              </Grid>
            </Section>
            <Section>
              <Grid container>
                <Grid item xs={12}>
                  <Center mb={4}>
                    <StaticImage
                      src="../../images/logo-sigma-pi-phi.png"
                      alt="elb logo"
                      placeholder="blurred"
                      width={300}
                    />
                  </Center>
                  <Box>
                    <Box component="blockquote">
                      In the first constitution the group proclaimed that:
                      Whereas it seems wise and good that men of ambition,
                      refinement and self-respect should seek the society of
                      each other.
                    </Box>
                  </Box>
                  <Box>
                    <Box component="blockquote">
                      Both for the mutual benefit and to be an example of the
                      higher type of manhood. Be it Resolved that a society be
                      organized for the purpose of binding men of like qualities
                      into a close, sacred, fraternal union, that they may know
                      the best of one another. And that each in this life may to
                      his full ability aid the other, and by concerted action
                      bring about those things that seem best for all that
                      cannot be accomplished by individual effort.
                    </Box>
                  </Box>
                  <Box>
                    <Typography align="center">
                      Learn more about Sigma Pi Phi at{" "}
                      <Link
                        href="https://www.sigmapiphi.org/home/"
                        targe="_blank"
                      >
                        www.sigmapiphi.org
                      </Link>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Section>
          </Container>
        </AnimateOnScroll>
      </Segment>
      <Segment variant="primary">
        <AnimateOnScroll animateIn="fadeIn" animateOut="fadeIn">
          <Container>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h4" color="common.white" align="center">
                  Foundation Officers
                </Typography>
                <FancyHr alt />
                <Center>
                  <Box>
                    {foundationOfficers.map(({ id, title, name }) => (
                      <Box key={id}>
                        <Box display="flex">
                          <Box mx={2} sx={{ minWidth: "200px" }}>
                            <Typography color="common.white">
                              {title}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography color="common.white">{name}</Typography>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Center>
              </Grid>
            </Grid>
          </Container>
        </AnimateOnScroll>
      </Segment>
    </>
  )
}
export default About
