import { Box } from "@mui/material"
import { styled } from "@mui/system"
import { rem } from "polished"

const StyledSection = styled(Box)({
  paddingTop: 4,
  paddingBottom: 4,
})
const StyledDivider = styled(Box)(({ theme }) => ({
  marginTop: `${rem(100)}`,
  marginBottom: `${rem(100)}`,
  borderColor: `rgba(147,131,131,0.1)`,
  [theme.breakpoints.up("sm")]: {
    marginTop: `${rem(75)}`,
    marginBottom: `${rem(75)}`,
  },
}))

export { StyledSection, StyledDivider }
