import React from "react"
import PropTypes from "prop-types"
import { Box } from "@mui/material"

import { StyledSection, StyledDivider } from "./Section.styled"

const Section = ({ children, divider, ...otherProps }) => {
  return (
    <StyledSection {...otherProps}>
      {children}
      {divider && (
        <Box>
          <StyledDivider component="hr"></StyledDivider>
        </Box>
      )}
    </StyledSection>
  )
}

Section.propTypes = {
  children: PropTypes.node,
  divider: PropTypes.bool,
}

export default Section
